import { SimpleProduct } from '@msdyn365-commerce/retail-proxy';
import { generateImageUrl } from '@msdyn365-commerce-modules/retail-actions';
import { ICommerceApiSettings } from '@msdyn365-commerce/core';

export const generateImagePath = (itemId: string, colour: string): string => {
    return `Products/${itemId}_${colour}_000_001.jpg`;
};

export const generateColourImageUrl = (product: SimpleProduct, apiSettings: ICommerceApiSettings): string | undefined => {
    const colour = product.Dimensions?.find(item => item.DimensionTypeValue === 1)?.DimensionValue?.Value;
    const imageUrl = generateImageUrl(generateImagePath(product.ItemId ?? '', colour ?? ''), apiSettings);
    return imageUrl;
};
